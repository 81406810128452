/* 
  ul li {display: inline-block;}
  ul li:hover {background: #ffffff;} */
  ul li:hover ul {display: block;}
  .ul {
    position: absolute;
    width: 200px;
    display: none;
    margin-top: 110px;
    margin-left: 35px;
  }
  ul li ul li { 
    background: #ffffff; 
    display: block; 
    
  }
  ul li ul li a {display:block !important;} 
  ul li ul li:hover {background: #ffffff;}